import { getInitials } from "@/utils/formatters/getInitials";
import { ICardInitialsProps } from "./interface";

import styles from "./styles.module.scss";

export default function CardInitials({
  username,
  className,
}: ICardInitialsProps) {
  const text = getInitials(username);

  return (
    <div className={className ? className : styles.card}>
      <span className={styles.card__initials}>{text}</span>
    </div>
  );
}
