import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { formatCoin } from "@/utils/formatters/formatCoin";
import coin from "@/assets/icons/coin.png";
import pearl from "@/assets/icons/pearls.png";
import tonImage from "@/assets/icons/ton.png";
import plus from "@/assets/icons/plus.png";
import boost from "@/assets/icons/boost.png";
import booster from "@/assets/icons/buster.png";
import Link from "next/link";
import { PROFILE_LINK } from "./header.constants";
import Avatar from "@/components/Avatar";
import HudButtons from "@/components/HudButtons";
import { formatTime } from "@/utils/formatters/formatTime";
import { setCurrentPage } from "@/store/shop/shopSlice";
import { useRouter } from "next/router";

import styles from "./styles.module.scss";
import useClickSound from "@/hooks/useClickSound";

export default function Header() {
  const { balance } = useSelector((state: RootState) => state.user);
  const { multipliers_boosters } = balance;
  const router = useRouter();
  const isBoosted = multipliers_boosters.time_remaing > 0;
  const time = formatTime(multipliers_boosters.time_remaing);
  const multiplier = multipliers_boosters.booster;
  const { coins, pearls, ton } = balance;
  const dispatch = useDispatch();
  const playSound = useClickSound();

  const handleClickAddPearls = () => {
    playSound();
    dispatch(setCurrentPage("ЖЕМЧУГ"));
    router.push("/shop");
  };

  const handleClickProfile = () => {
    playSound();
    router.push(PROFILE_LINK);
  };

  return (
    <header className={styles.header}>
      <div onClick={handleClickProfile}>
        <Avatar
          className={styles.avatar__wrapper}
          classNameHat={styles.hat}
          classNameImage={styles.avatar__image}
        />
      </div>
      <div className={styles.coins__wrapper}>
        <div className={styles.coins__container}>
          <div className={styles.coin__wrapper}>
            <Image
              className={styles.coin__icon}
              src={tonImage}
              alt="ton"
              width={35}
              height={35}
            />
            <div className={styles.coin__value__wrapper}>
              <span className={styles.coin__value}>{formatCoin(ton)}</span>
            </div>
          </div>
          <div className={styles.coin__wrapper}>
            <Image
              className={styles.coin__icon}
              src={pearl}
              alt="pearl"
              width={35}
              height={35}
            />
            <div className={styles.coin__value__wrapper}>
              <span className={styles.coin__value}>{formatCoin(pearls)}</span>
            </div>
            <Image
              className={styles.coin__icon__add}
              src={plus}
              alt="pearl button add"
              onClick={handleClickAddPearls}
              width={28}
              height={28}
            />
          </div>
          <div className={styles.coin__wrapper}>
            <Image
              className={styles.coin__icon}
              src={coin}
              alt="coin"
              width={35}
              height={35}
            />
            <div className={styles.coin__value__wrapper}>
              <span className={styles.coin__value}>{formatCoin(coins)}</span>
            </div>
            {isBoosted && (
              <div>
                <Image
                  className={styles.coin__value__boost}
                  width={25}
                  src={boost}
                  alt="booster"
                />
                <span className={styles.coin__value__boost__multiplier}>
                  {multiplier}{" "}
                </span>
                <Image
                  className={styles.coin__value__boost__image}
                  width={18}
                  src={booster}
                  alt="booster"
                />
                <span
                  className={`${styles.coin__value__boost__time} ${
                    multipliers_boosters.time_remaing > 3600
                      ? styles.coin__value__boost__time__long
                      : ""
                  }`}
                >
                  {time}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <HudButtons />
    </header>
  );
}
