import leaderboard from "@/assets/hud/leaderboard.png";
import chest from "@/assets/hud/chest.png";
import map from "@/assets/hud/map.png";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import Link from "next/link";
import Image from "next/image";

import styles from "./styles.module.scss";
import { useRouter } from "next/router";
import useClickSound from "@/hooks/useClickSound";

export default function HudButtons() {
  const router = useRouter();
  const playSound = useClickSound();
  const { keys_cheast } = useSelector((state: RootState) => state.user.balance);

  const handleNavigate = (path: string) => {
    playSound();
    router.push(path);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__right_column}>
        <div
          className={styles.link__chest}
          onClick={handleNavigate.bind(null, "/chest")}
        >
          <Image
            className={styles.image}
            src={chest}
            alt="leaderboard"
            width={48}
            height={48}
          />
          <span className={styles.chest__quantity}>X{keys_cheast}</span>
        </div>
      </div>
      <div className={styles.wrapper__left_column}>
        <div
          className={styles.link}
          onClick={handleNavigate.bind(null, "/map")}
        >
          <Image
            className={styles.image}
            src={map}
            alt="leaderboard"
            width={48}
            height={48}
          />
        </div>
        <div
          className={styles.link}
          onClick={handleNavigate.bind(null, "/leaderboard")}
        >
          <Image
            className={styles.image}
            src={leaderboard}
            alt="leaderboard"
            width={48}
            height={48}
          />
        </div>
      </div>
    </div>
  );
}
