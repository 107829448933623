import Image from "next/image";
import { IFooterCard } from "./interfaces";
import Link from "next/link";


import styles from "./styles.module.scss";

const FooterCard = ({
  src,
  alt,
  name,
  href,
  isActive,
  onClick,
}: IFooterCard) => {
  const handleClick = () => {
    onClick(name);
  };

  return (
    <li
      className={`${styles.footer__nav__item} ${isActive ? styles.active : ""}`}
      onClick={handleClick}
    >
      <Link href={href}>
        <Image src={src} alt={alt} width={64} height={64} />
        {isActive && <span className={styles.item_name}>{name}</span>}
      </Link>
    </li>
  );
};

export default FooterCard;
