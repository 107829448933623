import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FooterCard from "../Cards/FooterCard";
import { NAV_ITEMS } from "./footer.constants";
import useClickSound from "@/hooks/useClickSound";
import { RootState } from "@/store";
import { setCurrentItemMenu } from "@/store/hud/hudSlice";

import styles from "./styles.module.scss";


export default function Footer() {
  const { currentItemMenu } = useSelector((state: RootState) => state.hud);
  const playSound = useClickSound();
  const dispatch = useDispatch();

  const handleItemClick = React.useCallback((name: string) => {
    if(currentItemMenu==name) return;
    playSound();
    dispatch(setCurrentItemMenu(name));
  }, []);

  const isActiveItem = React.useCallback(
    (name: string) => currentItemMenu.toLowerCase() === name.toLowerCase(),
    [currentItemMenu]
  );


  return (
    <footer className={styles.footer}>
      <nav className={styles.footer__nav}>
        <ul className={styles.footer__nav__list}>
          {NAV_ITEMS.map((item) => (
            <FooterCard
              key={item.name}
              name={item.name}
              href={item.href}
              isActive={isActiveItem(item.href)}
              onClick={handleItemClick}
              src={item.src}
              alt={item.alt}
            />
          ))}
        </ul>
      </nav>
    </footer>
  );
}
